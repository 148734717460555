import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
import myConfig from "../../configs/config";
import { fetchFarms } from "../../store/actions/actions";
import AddButton from "../../components/Add_button";
import { toast } from "react-toastify";
import { FaTrash, FaEdit } from "react-icons/fa";
import MDSpinner from "react-md-spinner";
import { Link } from "react-router-dom";

function ManageFarm({ t }) {
  const [loading, setLoading] = useState(false);
  const farms = useSelector((state) => state.farm);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Agriconnected - " + t("farms.manageFarm");
  }, []);

  const handleDelete = async (farmId) => {
    if (window.confirm(t("farms.confirmDelete"))) {
      setLoading(true);
      try {
        const { status } = await axios({
          baseURL: `${myConfig.API_URL}/farm/${farmId}/`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("client-token")}`,
          },
        });
        if (status === 204) {
          toast.success(
            t("farms.deleteSuccess", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          );
        }
        dispatch(fetchFarms());
      } catch (error) {
        toast.error(t("farms.deleteError"));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="x_panel">
        <div className="x_title">
          <h2>{t("farms.manageFarms")}</h2>
          <div className="clearfix"></div>
        </div>
        <AddButton url="/new/" classname="btn btn-primary btn-default-add" />
        <div className="x_content">
          {loading ? (
            <MDSpinner />
          ) : (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("farms.name")}</th>
                    <th>{t("farms.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {farms.map((farm) => (
                    <tr key={farm.id}>
                      <td>{farm.nome_da_fazenda}</td>
                      <td>
                        <Link to={`/${farm.id}/change/`}>
                          <button className="btn btn-primary btn-sm">
                            <FaEdit /> {t("common.edit")}
                          </button>
                        </Link>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDelete(farm.id)}
                        >
                          <FaTrash /> {t("common.delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ManageFarm);
