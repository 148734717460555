import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Navbar, NavLink } from "reactstrap";
import { ListGroup } from "reactstrap";
import { isEmpty } from "../../helpers/utils";
import { connect } from "react-redux";
import myConfig from "../../configs/config";

import { Hidden } from "@material-ui/core/";

import { withRouter } from "react-router";

import {
  FaBars,
  FaPlus,
  FaAngleDown,
  FaEdit,
  FaUsers,
  FaUser,
  FaSignOutAlt,
} from "react-icons/fa";

import "./Base.css";

class TopNavigation extends Component {
  state = {
    user: [],
    isOpen: true,
  };

  componentWillMount() {
    if (localStorage.getItem("client") !== null) {
      this.setState({ user: JSON.parse(localStorage.getItem("client")) });
    } else {
      // console.log(localStorage.getItem("client"));
      setTimeout(() => {
        this.setState({ user: JSON.parse(localStorage.getItem("client")) });
      }, 2000);
    }
  }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/account/login/";
  };

  toggleMenu = () => {
    let classnameBody = document.getElementsByTagName("body");

    if (classnameBody[0].className === "nav-md") {
      classnameBody[0].className = "nav-sm";
    } else if (classnameBody[0].className === "nav-sm") {
      classnameBody[0].className = "nav-md";
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { user } = this.state;
    let list_farms;

    if (!isEmpty(this.props.farms)) {
      list_farms = (
        <>
          {this.props.farms.map((farm) => (
            <li key={farm.id}>
              <a href={`${myConfig.ABSOLUTE_URL}${farm.id}/`}>
                <span>{farm.nome_da_fazenda.replace('"', "")}</span>
              </a>
            </li>
          ))}
        </>
      );
    }

    return (
      <>
        <div className="fixed top-navigation">
          <div className="top_nav">
            <div className="nav_menu">
              <Navbar>
                <div className="nav toggle">
                  <Hidden xsDown>
                    <NavLink
                      id="menu_toggle"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Esconder/ Mostrar"
                      onClick={this.toggleMenu}
                    >
                      <FaBars />
                    </NavLink>
                  </Hidden>
                  <Hidden only={["sm", "lg", "md"]}>
                    <Link to={`/${this.props.farm_id}/home/`}>
                      <FaBars />
                    </Link>
                  </Hidden>
                </div>

                <ListGroup className="nav navbar-nav navbar-right">
                  <li>
                    <NavLink
                      to="javascript:;"
                      className="user-profile dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {this.props.farm_name}
                      <span className="span"></span>
                      {/* <span className="fa fa-angle-down"> </span> */}
                      <FaAngleDown />
                    </NavLink>
                    <ListGroup className="list_farms dropdown-menu dropdown-usermenu pull-right">
                      <li>
                        <Link to={`/${this.props.farm_id}/change/`}>
                          <span className="span">
                            {/* <i className="fas fa-edit"></i> */}
                            <FaEdit />
                            {this.props.t("Editar")}
                          </span>
                        </Link>
                      </li>

                      {list_farms}

                      <li>
                        <Link to="/new/">
                          <span className="span">
                            {/* <i className="fas fa-plus"></i> */}
                            <FaPlus />
                            {this.props.t("Adicionar Nova Fazenda")}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/farms/">
                          <span className="span">
                            {/* <i className="fas fa-list"></i> */}
                            <FaUsers />
                            {this.props.t("Gerenciar Fazendas")}
                          </span>
                        </Link>
                      </li>
                    </ListGroup>
                  </li>

                  <li>
                    <NavLink
                      href="#"
                      className="user-profile dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={user.thumbnail} alt="thumbnail" />
                      {/* <span className=" fa fa-angle-down"></span> */}
                      <FaAngleDown />
                    </NavLink>
                    <ListGroup className="dropdown-menu dropdown-usermenu pull-right">
                      <li>
                        <Link to={`/account/update/${user.id}/`}>
                          <span className="span">
                            {/* <i className="fas fa-user"></i> */}
                            <FaUser />
                            {this.props.t("Perfil")}
                          </span>
                        </Link>
                      </li>

                      <li>
                        <Link to={`/${this.props.farm_id}/administrator/`}>
                          <span className="span">
                            {/* <i className="fas fa-users"></i> */}
                            <FaUsers />
                            {this.props.t("Administradores")}
                          </span>
                        </Link>
                      </li>

                      <li>
                        <a onClick={this.handleLogout}>
                          {/* <i className="fas fa-sign-out-alt"></i> */}
                          <FaSignOutAlt />
                          {this.props.t("Sair")}
                        </a>
                      </li>
                    </ListGroup>
                  </li>
                </ListGroup>
              </Navbar>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  farms: store.farm,
});
export default withTranslation()(
  connect(mapStateToProps)(withRouter(TopNavigation))
);
