import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import Login from "./containers/Accounts/Login";
import Signup from "./containers/Accounts/Signup";
import Layout from "./containers/Base/Layout";
import FormUpdateUser from "./containers/Accounts/FormUpdateUser";
import ResetPassword from "./containers/Accounts/ResetPassword";
import Loading from "./components/Loading";
import Routes from "./Routes";
import myConfig from "./configs/config";
import addInterceptors from "./configs/interceptor";
import ReactGa from "react-ga";

import * as actions from "./store/actions/actions";

import Recovery from "./containers/Accounts/Recovery";
import ResetPasswordConfirm from "./containers/Accounts/ResetPasswordConfirm";

import PWAPrompt from "react-ios-pwa-prompt";

import i18n from "./i18n";

// CSS
import "react-toastify/dist/ReactToastify.css";

// ICONS
import ConfirmEmail from "./containers/Accounts/ConfirmEmail";

function initGa(client) {
  ReactGa.initialize(myConfig.GA_ID);
  ReactGa.ga("create", myConfig.GA_ID, "auto", {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: client.email,
      clientId: client.id,
    },
  });
  ReactGa.ga("set", "userId", client.email);
  ReactGa.pageview(window.location.pathname + window.location.search);
}

class App extends Component {
  state = {
    isAuthenticated: true,
    isLoading: true,
    farm_id: "new",
    next: "/",
  };

  async fetchUser() {
    try {
      console.log("try ***********");

      const url = myConfig.API_URL + "/accounts/me/";

      await axios({
        baseURL: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("client-token")}`,
        },
      }).then((res) => {
        const client = res.data;
        const farm = res.data.current_farm;
        const farm_name = res.data.current_farm_name.replace('"', "");

        localStorage.setItem("farm", JSON.stringify(farm));
        localStorage.setItem("farm_name", farm_name);
        localStorage.setItem("client", JSON.stringify(client));
        localStorage.setItem("client_id", JSON.stringify(client.id));

        initGa(client);

        if (client.language !== "") {
          // console.log(client.language)
          i18n.changeLanguage(client.language);
        }

        this.props.fetchFarms();
        this.setState({
          isAuthenticated: true,
          isLoading: false,
          farm_id: farm,
        });
      });
      // console.log(response.status)
    } catch (err) {
      console.log("Erro ao renderizar app", err);
      window.localStorage.clear();
      this.setState({ isAuthenticated: false, isLoading: false });
      window.location.href = "/";
    }
  }

  componentWillMount() {
    addInterceptors();
    if (localStorage.getItem("client-token")) {
      // console.log('tem token!!');
      this.fetchUser();
    } else {
      let next = window.location.pathname + window.location.search;
      next = encodeURIComponent(next);
      this.setState({ isAuthenticated: false, isLoading: false, next: next });
    }
  }

  render() {
    let routes = (
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path="/account/login" render={() => <Redirect to="/" />} />
            <Route path="/account/password_change/" component={ResetPassword} />
            } />
            <Route
              path="/account/update/:user_id/"
              component={FormUpdateUser}
            />
            } />
            <Route
              exact
              path="/"
              render={() => <Redirect to={`/${this.state.farm_id}/`} />}
            />
            <Route path="/:farm_id/*" component={Routes} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
    if (!this.state.isAuthenticated) {
      routes = (
        <BrowserRouter>
          <Switch>
            <Route path="/account/recovery/" component={Recovery} />
            <Route
              path="/account/reset/:token/set-password/"
              component={ResetPasswordConfirm}
            />

            <Route path="/account/login/:token/" component={ConfirmEmail} />

            <Route path="/account/login/" component={Login} />
            <Route path="/account/signup/" component={Signup} />

            <Route
              path="/*"
              render={() => (
                <Redirect to={"/account/login/?next=" + this.state.next} />
              )}
            />
          </Switch>
        </BrowserRouter>
      );
    }

    return (
      <>
        <PWAPrompt
          copyTitle="Adicionar a tela inicial"
          copyBody="Este site possui funcionalidade de aplicativo. Adicione-o à sua tela inicial para usá-lo em tela cheia e offline."
          copyShareButtonLabel="1) Pressione o botão 'Compartilhar'"
          copyAddHomeButtonLabel="2) Pressione 'Adicionar à tela inicial'"
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
        />

        {this.state.isLoading ? <Loading /> : routes}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFarms: () => dispatch(actions.fetchFarms()),
  };
};

const mapStateToProps = (store) => ({
  farm: store.current_farm,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
